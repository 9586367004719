.disque-seul {
    border-radius:4px;
    overflow:hidden;
    box-shadow: 0px 0px 20px #0002;
    margin-bottom:15px;
}
.disque-production {
    color:grey;
    margin-bottom:15px;
}
