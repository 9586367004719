.video-players {
    position:absolute;
    width:100%;
    z-index:10;
    top:0;
    left:-10000px;
    opacity:0;
    transition: left 0s linear 0.5s, opacity 0.5s;
}
.video-players h3 {
    font-family: 'GillSans-Light';
    font-size: 100px;
    color:#EEE;
    position:relative;
    left:-30px;
    text-transform: lowercase;
}

.video-players-container {
    position:absolute;
}
.video-players.show, .video-players.mini {
    position:absolute;
    z-index:1000;
    opacity:1;
    top:0;
    left:0;
    transition: left 0s, opacity 0.5s linear 0.2s;
}
.video-players.show {
    min-height:calc( 100vh - 4vw );
    width:100%;
    overflow:hidden;
    top:calc(100vh);
    color:#FFF;
}
.video-players.mini .video-players-container {
    position:fixed;
    z-index:10;
    opacity:1;
    bottom:15px;
    left:15px;
    width:300px;
    height: 169px;
    border-radius:4px;
    overflow: hidden;
    transition: left 0s, opacity 0.5s linear 0.2s;
}
.video-players .video {
    opacity:0;
    z-index:0;
    top:0;
    right:0;
    bottom:0;
    z-index:0;
    left:0;
    position:absolute;
    transition: opacity 2s;
}
.video-players .video.selected {
    position:absolute;
    position:relative;
    z-index:1;
    opacity:1;
    transition: opacity 2s;
}
.video-players .videos-menu {
    position:absolute;
    top:0;
    left:0;
    z-index:11;
    opacity:1;
    transition: opacity 0.5s, left 0s;
}
.video-players.hide-menu .videos-menu {
    opacity:0;
    top:0;
    left:-10000px;
    transition: opacity 0.5s, left 0s linear 0.5s;
}
.video-players.mini .videos-menu {
    display:none;
}
.video-players .video-players-container {
    filter: blur(10px);
    transition: filter 1s;
}
.video-players.hide-menu .video-players-container, .video-players.mini .video-players-container {
    filter: blur(0);
    transition: filter 1s;
}
.menu-slide {
    cursor:pointer;
}
.menu-slide .caption {
   position: absolute;
   bottom: 10px;
   left: 30px;
   right:30px;
   color: #FFF;
   text-shadow: 0 0 10px #0008;
}
.menu-slide h3.titre  {
    text-align:center;
	font-family: 'Syncopate-Fab';
    margin:15px 0;
    font-size:1.2em;
}
.menu-slide .caption h3 {
	margin: 0;
	position: absolute;
	bottom: 0;
	left: 0;
	color: #FFF;
	width: 100%;
	text-align: left;
}
@media (max-width:767px) {
    .video-players.show {
        top:calc(59vw + 60px);
        min-height:calc( 100vh - 60px );
    }
    .video-players h3 {
        font-size: 50px;
        left:0;
    }
}
@media (min-width:768px ) and (max-width:991px) {
    .video-players.show {
        top:calc(59vw + 60px);
        min-height:calc( 100vh - 60px );
    }
    .video-players h3 {
        font-size: 50px;
        left:0;
    }
}
