.billet-date {
    padding:5px;
    font-family: 'GillSans';
    font-size:20px;
    color:#999;
}
.billet-titre.small {
    font-size:30px;
}
.pj-taille {
    color:#666;
    font-size:12px;
}
.billet .fb {
    background-image: url(../assets/fb_gris.svg);
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
    height:20px;
    width:20px;
    margin:15px 0;
    padding-right:25px;
    font-size: 14px;
    color:#888;
    width:100%;
    text-align:right;
}
