.xs-menu {
    position:absolute;
    top:0;
    width:100%;
    height:60px;
    z-index:100;
    background-color:#000;
    color:#FFF;
}
.xs-menu h3 {
    margin:0;
    text-align:center;
    padding-top:10px;
    line-height:40px;
    font-size:40px;
    font-family: 'GillSans-Light';
}
.entered .xs-menu {
    position:fixed;
    z-index:100;
    width:100%;
    top:0;
}
.burger {
    display:block;
    position:absolute;
    width:50px;
    left:10px;
    top:5px;
    cursor:pointer;
}
.burger svg {
    fill:#FFF;
}
.MuiMenuItem-root.xs-menu-item {
    font-size:30px;
    font-family: 'GillSans-Light';
}
