.router-route {
    opacity:0;
    transition:opacity 0.5s;
    width:100%;
}
.router-route.router-current{
    position:relative;
    opacity:1;
    z-index:2;
    animation: rise 0.5s;
}
@keyframes rise {
    from {opacity:0;}
    to {opacity:1;}
}
.router-route.router-current.router-samecomponent {
    animation: none;
}
.router-route.router-previous{
    position: absolute;
    z-index:1;
    opacity:0;
}
.router {
    position:relative;
    top:0;
    left:0;
    width:100%;
}
.router-route>div:first-child {
    min-height:calc( 100vh - 4vw + 1px );
    padding-bottom:60px;
}
.xs .router-route>div:first-child, .sm .router-route>div:first-child, .md .router-route>div:first-child {
    padding-top:60px;
}
.router-route>div:first-child.nopad {
    padding-bottom:0;
}
.router-route>div:first-child {
    min-height:calc( 100vh + 1px );
}
