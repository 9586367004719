.tuile-agenda-outer {
    width:100%;
    position:relative;
}
.tuile-agenda {
	border-radius: 4px;
    background-color:#333;
	overflow: hidden;
	box-shadow: 0px 0px 20px #0002;
	color:#FFF;
	width:200px;
	position:relative;
	transform-origin: left top;
}
.tuile-agenda .date-debut, .tuile-agenda .date-fin {
    position:absolute;
    top:0;
    width:100%;
    left:0;
}
.tuile-agenda.plusieurs-jours .date-debut {
    animation: 6s linear 0s jour_debut infinite;
}
.tuile-agenda.plusieurs-jours .date-fin {
    animation: 6s linear 0s jour_fin infinite;
}
.tuile-agenda .jour {
	position: absolute;
	font-family: GillSans-light;
	font-size: 44px;
	line-height: 50px;
	top: 3px;
	right: 120px;
}
.tuile-agenda .mois {
	font-family: 'Syncopate-Fab';
	position: absolute;
	left: 90px;
	top: 12px;
	font-size: 25px;
	line-height: 20px;
}
.tuile-agenda .annee {
	font-family: 'Syncopate-Fab';
	left: 92px;
	top: 28px;
	position: absolute;
	font-size: 14px;
	letter-spacing: 5px;
	color: #cfcfcf;
}

.tuile-agenda .type {
	position: absolute;
	width: 100%;
	left: 0;
	top: 55px;
	background-color: #FFF2;
	font-family: 'Syncopate-Fab';
	font-size: 12px;
	//padding-left: 10px;
    padding-top:1px;
    text-align:center;
}
.tuile-agenda .heure {
	font-family: 'Syncopate-Fab';
	font-size: 12px;
}
.tuile-agenda.ac .heure {
	opacity:0;
}
.tuile-agenda img {
    position: absolute;
	bottom: 0;
	left:0;
}
.tuile-agenda .agenda {
	font-family: 'GillSans-Light';
	position: absolute;
	bottom: 0;
	width: 100%;
	left: 0;
	text-align: center;
	font-size: 27px;
	padding: 5px 10px;
	line-height: 30px;
	text-shadow: 0 0 15px #000;
}
.tuile-agenda.ac {
    background-color:#666;
    	}
.tuile-agenda .ville-lieu {
	padding: 78px 5px 100px 5px;
	text-align:center;
}
.tuile-agenda .lieu {
	font-family: 'GillSans-LightItalic';
	font-size: 20px;
	line-height: 20px;
}
.tuile-agenda .ville {
	font-family: 'Syncopate-Fab';
    font-size: 16px;
    line-height: 18px;
	font-weight: 600;
}
.ville-lieu table {
    width:100%;
}
td.ville {
    height:36px;
    position:relative;
}
td.ville > div {
    position:absolute;
    top:0;
    left:0;
    width:100%;
}
td.lieu {
    height:40px;
    position:relative;
}
td.lieu > div {
    position:absolute;
    top:0;
    left:0;
    width:100%;
}
td.heure {
    height:30px;
}
@keyframes jour_debut {
    0% { opacity:1; }
    40% { opacity:1; }
    50% { opacity:0; }
    80% { opacity:0; }
    90% { opacity:1; }
    100% { opacity:1; }
}
@keyframes jour_fin {
    0% { opacity:0; }
    30% { opacity:0; }
    40% { opacity:1; }
    90% { opacity:1; }
    100% { opacity:0; }
}
.tuile-agenda-outer .sticker {
	position: absolute;
	bottom: -22px;
	background: #FFF;
	padding: 5px 10px;
	border-radius: 4px;
	box-shadow: 0px 0px 20px #0002;
	right: 15px;
	cursor: pointer;
    color:#333;
}
