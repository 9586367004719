.evenement-tuile {
    margin-bottom:30px;
}
.evenement-titre {
    font-family:'GillSans-light';
    font-size:60px;
    color:#666;
}
.evenement-titre.small {
    font-size:30px;
}
.pj-taille {
    color:#666;
    font-size:12px;
}
.detail-date .fb {
    background-image: url(../assets/fb_gris.svg);
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
    height:20px;
    width:20px;
    margin:15px 0;
    padding-right:25px;
    font-size: 14px;
    color:#888;
    width:100%;
    text-align:right;
}
@media (max-width:767px) {
    .evenement-titre {
        font-size:40px;
    }
    .evenement-titre.small {
        font-size:30px;
    }
}
