@import 'utils/variables.scss';
@font-face {
  font-family: 'Syncopate-Fab';
  src: url('fonts/Syncopate-Fab.eot?#iefix') format('embedded-opentype'),  url('fonts/Syncopate-Fab.woff') format('woff'), url('fonts/Syncopate-Fab.ttf')  format('truetype'), url('fonts/Syncopate-Fab.svg#Syncopate-Fab') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSans-Regular';
  src: url('fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),  url('fonts/OpenSans-Regular.woff') format('woff'), url('fonts/OpenSans-Regular.ttf')  format('truetype'), url('fonts/OpenSans-Regular.svg#OpenSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GillSans-Light';
  src: url('fonts/GillSans-Light.eot?#iefix') format('embedded-opentype'),  url('fonts/GillSans-Light.woff') format('woff'), url('fonts/GillSans-Light.ttf')  format('truetype'), url('fonts/GillSans-Light.svg#GillSans-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GillSans-LightItalic';
  src: url('fonts/GillSans-LightItalic.eot?#iefix') format('embedded-opentype'),  url('fonts/GillSans-LightItalic.woff') format('woff'), url('fonts/GillSans-LightItalic.ttf')  format('truetype'), url('fonts/GillSans-LightItalic.svg#GillSans-LightItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GillSans';
  src: url('fonts/GillSans.eot?#iefix') format('embedded-opentype'),  url('fonts/GillSans.woff') format('woff'), url('fonts/GillSans.ttf')  format('truetype'), url('fonts/GillSans.svg#GillSans') format('svg');
  font-weight: normal;
  font-style: normal;
}
body {
    margin:0;
    padding:0;
    font-family:'OpenSans-Regular';
    font-size:16px;
}
.img-responsive {
    width:100%;
}
.app {
    opacity:0;
    transition:opacity 0.5s;
}
.app.loaded {
    opacity:1;
}

.wait, .wait-sync {
    opacity:0;
    transition: opacity 1s;
}
.ok {
    opacity:1;
    transition: opacity 1s;
}

.titre {
    font-family: 'Syncopate-Fab';
    font-weight: lighter;
}
.entete {
    position: relative;
    width:100%;
    height:100vh;
    max-height:59vw;
    overflow:hidden;
    background-color:#000;
}
.entete h3 {
    cursor:pointer;
}
.entete-image{
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
}
.content {
    position: relative;
    min-height:calc( 100vh - 4vw );
    width:100%;
    overflow:hidden;
}
.entete img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.entete h3 {
    font-family: 'GillSans-Light';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom:7vw;
    font-size:10vw;
    color:#FFF;
    text-shadow: 0 0 10px #0008;
    z-index:2;
    line-height:100%;
    margin:0;
    z-index:1002;
}
nav {
    font-family: 'GillSans-Light';
    position:absolute;
    height:4vw;
    background-color:#0008;
    width:100%;
    bottom:0;
    z-index:1001;
    transition: background-color 1s;
}
.entered nav {
    position:fixed;
    top:0;
    left:0;
    z-index:1001;
    background-color: #000;
    transition: background-color 1s;
}
.entered .entete h3 {
    position:fixed;
    top:0.5vw;
    left:0;
    bottom:unset;
    z-index:1002;
}
nav ul {
    list-style:none;
    margin:0;
    padding:0;
    text-align:center;
}
nav li {
    display: inline-block;
    margin:0;
    padding:1.25vw;
    font-size:1.5vw;
    line-height:1.5vw;
}
nav li, nav li a, nav li a:hover, nav li a:active, nav li a:visited {
    text-decoration:none;
    color:#fff;
}
a:hover, a:active, a:visited {
    text-decoration:none;
}
.credits {
    color: #fff8;
    text-shadow: 0 0 2px #0008;
    position: absolute;
    right: 5px;
    bottom: 105px;
    transform-origin: bottom left;
    transform: translate(100%) rotate(270deg);
    font-size: 12px;
}
.zone1 {
    position:absolute;
    height:calc( 100vh - 4vw );
    max-height:calc( 59vw - 4vw );
    width:100%;
    top:0;
    left:0;
}
.liste {
    margin-top:30px;
}
.intro {
    margin-bottom:30px;
}
.main-audio-player {
    position:fixed;
    bottom:0;
    opacity:0.5;
    background-color: #FFF5;
    padding:0 15px;
    width:100%;
    z-index:12;
    padding-top:15px;
    transform: translateY(0);
    transition: opacity 0.5s, background-color 0.5s, transform 0.5s;
}
.main-audio-player.ko {
    opacity:0.5;
    background-color: #FFF0;
    transform: translateY(100px);
    transition: opacity 0.5s, background-color 0.5s, transform 1s;
}
.main-audio-player:hover {
    transform: translateY(0);
    opacity:1;
    background-color: #FFFE;
}
.content h3.titre {
    font-family: 'GillSans-Light';
    font-size: 100px;
    color:#EEE;
    position:relative;
    left:-30px;
    text-transform: lowercase;

}
.content h3.titre span {
    font-family: 'GillSans-Light';
    font-size: 50px;
    color:#666;
}
.pointer {
    cursor:pointer;
}
.clearfix {
    clear:both;
}
.tuile, .shadow {
	border-radius: 4px;
	background: #FFFA;
	overflow: hidden;
	box-shadow: 0px 0px 20px #0002;
}
.tuile {
	margin-bottom:30px;
}
.tuile td {
    text-align:center;
    font-family: 'Syncopate-Fab';
    font-size:1.5em;
    color:#000;
    padding:0 10px;
}
.tuile table {
    width:100%;
}
.tuile td {
    height:80px;
}
.inter {
    height:30px;
}
.pad0 {
    padding:0;
}
#main-container>div {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    display:none;
    opacity:0;
    padding-bottom:30px;
}
#main-container>div.page-enter {
    display:block;
    z-index:2;
    opacity:0;
}
#main-container>div.page-enter-active {
    display:block;
    opacity:1;
    transition: opacity 0.3s;
}
#main-container>div.page-exit {
    display:block;
    z-index:1;
    opacity:1;
}
#main-container>div.page-exit-active {
    display:block;
    opacity:0;
    transition: opacity 0.3s;
}
.retour-ctrl {
	border-radius: 4px;
	background-color:#333;
	box-shadow: 0px 0px 20px #0002;
	color:#FFF;
    padding: 8px;
    margin-right: 8px;
    margin-top: 4px;
    user-select:none;
    display: inline-block;
}
.retour-btn, .nav-btn {
    margin-bottom:30px;
    padding: 8px 0;
}
.plus-btn {
    margin-top:60px;
}
.next {
    opacity:0.1;
}
.next.pointer {
    opacity:1;
}
.prev {
    opacity:0.1;
}
.prev.pointer {
    opacity:1;
}
.prev.pointer:hover, .next.pointer:hover  {
    opacity:0.9;
}
.img-bandeau {
    position:relative;
    margin-top:60px;
}
.img-bandeau-titre {
    font-family: 'GillSans-Light';
    position:absolute;
    bottom:15px;
    left:15px;
    color:#FFFA;
    font-size:80px;
    line-height:80px;
}
h3.spip {
    font-family:'GillSans-Light';
    font-size:40px;
    color:grey;
}
h1.grand-titre {
    font-family:'GillSans-Light';
    font-size:60px;
    color:#fff;
    background-color:#CCC;
    padding:15px;
    line-height:1em;
    border-radius:4px;
    box-shadow: 0 0 5px #0002;
    margin-top:60px;
}

@media (max-width:767px) {
    .entete {
        height:calc(100vh - 60px);
    }
    .zone1 {
        position:absolute;
        height:100%;
        max-height:100%;
        width:100%;
        top:0;
        left:0;
    }
    .entete h3 {
        display:none;
    }
    .entete nav {
        display:none;
    }
    .content h3.titre {
        font-family: 'GillSans-Light';
        font-size: 50px;
        color:#EEE;
        position:relative;
        left:0px;
        text-transform: lowercase;
    }
    .plan ul {
        width:100%;
    }
    .main-audio-player {
        opacity:0.9;
        background-color: #FFF;
    }
    h1.grand-titre {
        font-family:'GillSans-Light';
        font-size:40px;
        color:#fff;
        background-color:#CCC;
        padding:15px;
        line-height:1em;
        border-radius:4px;
        box-shadow: 0 0 5px #0002;
        margin-top:60px;
        text-align:left;
    }
    .img-bandeau-titre {
        font-family: 'GillSans-Light';
        position:absolute;
        bottom:15px;
        left:15px;
        color:#FFFA;
        font-size:40px;
        line-height:40px;
    }
    h3.spip {
        font-family:'GillSans-Light';
        font-size:30px;
        color:grey;
        text-align:left;
    }
    .content {
        min-height:calc( 100vh - 60px );
    }
}
@media (min-width:768px ) and (max-width:991px) {
    .entete {
        height:calc(100vh - 60px);
    }
    .zone1 {
        position:absolute;
        height:100%;
        max-height:100%;
        width:100%;
        top:0;
        left:0;
    }
    .entete h3 {
        display:none;
    }
    .entete nav {
        display:none;
    }
    .content h3.titre {
        font-family: 'GillSans-Light';
        font-size: 50px;
        color:#EEE;
        position:relative;
        left:0px;
        text-transform: lowercase;
    }
    .main-audio-player {
        opacity:0.9;
        background-color: #FFF;
    }
    h1.grand-titre {
        font-family:'GillSans-Light';
        font-size:40px;
        color:#fff;
        background-color:#CCC;
        padding:15px;
        line-height:1em;
        border-radius:4px;
        box-shadow: 0 0 5px #0002;
        margin-top:60px;
        text-align:left;
    }
    .img-bandeau-titre {
        font-family: 'GillSans-Light';
        position:absolute;
        bottom:15px;
        left:15px;
        color:#FFFA;
        font-size:40px;
        line-height:40px;
    }
    h3.spip {
        font-family:'GillSans-Light';
        font-size:30px;
        color:grey;
        text-align:left;
    }
    .content {
        min-height:calc( 100vh - 60px );
    }
}
@media (min-width:992px) and (max-width:1199px) {
    .entete {
        height:calc(100vh - 60px);
    }
    .zone1 {
        position:absolute;
        height:100%;
        max-height:100%;
        width:100%;
        top:0;
        left:0;
    }
    .entete h3 {
        display:none;
    }
    .entete nav {
        display:none;
    }
    .content h3.titre {
        font-family: 'GillSans-Light';
        font-size: 50px;
        color:#EEE;
        position:relative;
        left:0px;
        text-transform: lowercase;
    }
    .main-audio-player {
        opacity:0.9;
        background-color: #FFF;
    }
    h1.grand-titre {
        font-family:'GillSans-Light';
        font-size:40px;
        color:#fff;
        background-color:#CCC;
        padding:15px;
        line-height:1em;
        border-radius:4px;
        box-shadow: 0 0 5px #0002;
        margin-top:60px;
        text-align:left;
    }
    .img-bandeau-titre {
        font-family: 'GillSans-Light';
        position:absolute;
        bottom:15px;
        left:15px;
        color:#FFFA;
        font-size:40px;
        line-height:40px;
    }
    h3.spip {
        font-family:'GillSans-Light';
        font-size:30px;
        color:grey;
        text-align:left;
    }
    .content {
        min-height:calc( 100vh - 60px );
    }
}
@media (min-width:1200px) and (max-width:1599px) {

}
@media (min-width:1600px) {

}
.lien {
  cursor:pointer;
  font-weight: bold;
}
