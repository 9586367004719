.controls-progressbar-container {
    position:relative;
    height:16px;
    user-select: none;
}
.controls-progressbar-mask {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
}
.controls-progressbar-background {
    position:absolute;
    top:7px;
    left:0;
    width:100%;
    height:4px;
    border-radius:2px;
    background-color:#EEE;
}
.controls-progressbar-progress {
    position:absolute;
    top:0;
    left:0;
    width:0%;
    height:4px;
    border-radius:2px;
    background-color:#000;
}
.controls-progressbar-target {
    position: absolute;
    left:0;
    top:-4px;
    height:10px;
    width:10px;
    border-radius:5px;
    opacity:0;
    background:#000;
    transition:opacity 0.5s;
}
.controls-progressbar-target.on {
    opacity:1;
}
.controls-progressbar-currenttime {
    position:absolute;
    top:-19px;
    right:0;
    width:50%;
    width:max-content;
    text-align:right;
    color:#999;
    font-family: 'GillSans';
}
