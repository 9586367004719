.swiper-container {
	margin-left: auto;
	margin-right: auto;
	position: relative;
	overflow: hidden;
	list-style: none;
	padding: 0;
	z-index: 1;
	padding-bottom: 25px;
	opacity:0;
	transition:opacity 0.5s;
}
.swiper-container.loaded {
	opacity:1;
}
.swiper-slide {
    cursor:pointer;
}
.swiper-pagination {
	width:100%;
}
.swiper-pagination .swiper-pagination-bullet{
	margin:0 5px;
}
.swiper-container-horizontal > .swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
	bottom: 0;
}
.swiper-pagination-bullet-active {
	opacity: 1;
	background: #003e67;
}
.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
	right: 21px;
	left: auto;
	top:calc(50% - 10px);
	text-shadow: #000C 0 0 20px;
	color: #FFF;
}
.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
	left: 21px;
	right: auto;
	top:calc(50% - 10px);
	text-shadow: #000C 0 0 20px;
	color: #FFF;
}
.swiper-container .shadow {
	border-radius: 4px;
	background: #FFFA;
	overflow: hidden;
	box-shadow: 0px 0px 20px #0002;
}
.swiper-container .caption {
	position: absolute;
	bottom: 10px;
	left: 30px;
	right:30px;
	color: #FFF;
	text-shadow: 0 0 10px #0008;
}
.swiper-container .titre {
    font-family: 'Syncopate-Fab';
}
.swiper-container h3.titre  {
    text-align:center;
	font-family: 'Syncopate-Fab';
    margin:15px 0;
    font-size:1.2em;
}
.swiper-container .caption h3 {
	margin: 0;
	position: absolute;
	bottom: 0;
	left: 0;
	color: #FFF;
	width: 100%;
	text-align: left;
}
